@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap");

* {
    font-family: "Work Sans", sans-serif;
}

:root{
    --webkit-text-track-display:none;
}



@media screen and (max-width: 768px) {
    .plyr--full-ui ::-webkit-media-text-track-container {
        display: var(--webkit-text-track-display);
      }
}

@media screen and (max-width: 479.9px) {
    html {
        font-size: 10px;
    }
    .plyr--full-ui ::-webkit-media-text-track-container {
        display: var(--webkit-text-track-display);
      }
}
@media screen and (max-width: 319.9px) {
    html {
        font-size: 8px;
    }
    .plyr--full-ui ::-webkit-media-text-track-container {
        display: var(--webkit-text-track-display);
      }
}
@media screen and (max-width: 239.9px) {
    html {
        font-size: 6px;
    }
    .plyr--full-ui ::-webkit-media-text-track-container {
        display: var(--webkit-text-track-display);
      }
}