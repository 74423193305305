.spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: #999;

    .spinner {
        display: inline-block;
        width: 30px;
        height: 30px;
        border: 2px solid #999;
        border-radius: 50%;
        border-top-color: #333;
        animation: spin 1s ease-in-out infinite;
        -webkit-animation: spin 1s ease-in-out infinite;
        margin: 10px;
    }

    @keyframes spin {
        to { -webkit-transform: rotate(360deg); }
    }

    @-webkit-keyframes spin {
        to { -webkit-transform: rotate(360deg); }
    }
}