:root {
  --plyr-color-main: #000000;
  --plyr-captions-background: rgba(20, 20, 20, 0.65);
  --plyr-captions-text-color: #fff;
}
html,
body {
  background-color: black;

  .plyr {
    height: 100vh;  
    max-height: -webkit-fill-available !important;
    margin: 0 auto;
  }
}

/* hide ALL scrollbars */
::-webkit-scrollbar {
  display: none;
}

// assign css variable
$plyrColorMain: var(--plyr-color-main);

#mobileOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: block;
  z-index:2;
}

.d-none {
  display: none !important;
}
