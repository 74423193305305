@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spinner-container{display:flex;align-items:center;justify-content:center;height:100vh;color:#999}.spinner-container .spinner{display:inline-block;width:30px;height:30px;border:2px solid #999;border-radius:50%;border-top-color:#333;animation:spin 1s ease-in-out infinite;-webkit-animation:spin 1s ease-in-out infinite;margin:10px}@keyframes spin{to{-webkit-transform:rotate(360deg)}}@-webkit-keyframes spin{to{-webkit-transform:rotate(360deg)}}

.error-container{display:flex;align-items:center;justify-content:center;flex-direction:column;height:100vh;color:#999}.error-container p{margin:4px}.error-container p br{margin:4px}

.overlay{position:absolute;top:0;bottom:0;left:0;right:0;display:flex;align-items:center;justify-content:center;background-color:rgba(0,0,0,0.5);padding:2.5rem}.overlay .overlay__btn{width:100%;height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center;background-color:transparent;border:1px solid white;border-radius:6px;color:white;font-size:2rem;font-weight:600;padding:5px;cursor:pointer}.overlay .overlay__btn:hover{border-width:3px}.overlay .overlay__btn .overlay__btn__icon{width:4.688rem;height:4.688rem;background-repeat:no-repeat;background-size:contain;background-position:center;flex-shrink:0;margin-bottom:0.875rem}

:root{--plyr-color-main: #000000;--plyr-captions-background: rgba(20, 20, 20, 0.65);--plyr-captions-text-color: #fff}html,body{background-color:black}html .plyr,body .plyr{height:100vh;max-height:-webkit-fill-available !important;margin:0 auto}::-webkit-scrollbar{display:none}#mobileOverlay{position:absolute;top:0;left:0;width:100vw;height:100vh;display:block;z-index:2}.d-none{display:none !important}

*{font-family:"Work Sans", sans-serif}:root{--webkit-text-track-display:none}@media screen and (max-width: 768px){.plyr--full-ui ::-webkit-media-text-track-container{display:none;display:var(--webkit-text-track-display)}}@media screen and (max-width: 479.9px){html{font-size:10px}.plyr--full-ui ::-webkit-media-text-track-container{display:none;display:var(--webkit-text-track-display)}}@media screen and (max-width: 319.9px){html{font-size:8px}.plyr--full-ui ::-webkit-media-text-track-container{display:none;display:var(--webkit-text-track-display)}}@media screen and (max-width: 239.9px){html{font-size:6px}.plyr--full-ui ::-webkit-media-text-track-container{display:none;display:var(--webkit-text-track-display)}}

