@mixin flex-layout($color) {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  color: $color;
}

.error-container {
  @include flex-layout($color: #999);

  p {
    margin: 4px;

    br {
      margin: 4px;
    }
  }
  
}
