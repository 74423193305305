.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 2.5rem;
    .overlay__btn {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 6px;
        color: white;
        font-size: 2rem;
        font-weight: 600;
        padding: 5px;
        cursor: pointer;
        &:hover {
            border-width: 3px;
        }
        .overlay__btn__icon {
            width: 4.688rem;
            height: 4.688rem;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            flex-shrink: 0;
            margin-bottom: 0.875rem;
        }
    }
}
